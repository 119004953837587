<template>
  <div class="fixed-price pt-4 pb-8" v-if="order">
    <vs-row class="block">
      <div class="flex pt-4">
        <label class="mr-10 pt-2">Gesamtsumme</label>
        <mg-select v-model="total" :options="options1" name="total"
                   placeholder="Gesamtsumme auswählen"
                   track-by="id" label="text" data-vv-as="Gesamtsumme"
                   v-validate="'required'">
        </mg-select>
        <span class="text-danger text-sm" v-if="errors.has('clients')">Dieses Feld ist ein Pflichtfeld</span>
      </div>
      <div class="flex pt-4 pb-4 border-bottom-light">
        <span class="mr-8 pt-2">Andere Summe: </span>
        <vs-input class="w-1/2" v-model="otherAmount"/>
      </div>
    </vs-row>
    <vs-row class="block mt-10">
      <vs-input class="w-full" v-model="order.title" :label="'Abschlussrechnung zu Angebot #'+`${order.invoice_number}`"></vs-input>
      <vs-alert color="#e24733" :active="true" icon="error" class="mb-4 mt-2"
                v-show="!order.title">
        Titel darf nicht leer sein
      </vs-alert>

      <label class="vs-input--label mt-4 block">Beschreibung</label>
      <vue-editor name="short_description" v-model="order.short_description" :editorToolbar="toolbar" data-vv-as="Beschreibung"  />

      <div class="mt-8">
        <div class="flex pb-4">
          <vs-checkbox v-model="alreadyBeenBilledCheckbox" class="w-1/7"></vs-checkbox>
          <div class="w-1/2">Abzüglich bereits Abgerechnetes</div>
        </div>
        <div class="flex pb-4">
          <div class="w-1/4"> <vs-checkbox v-model="alreadyBeenBilledCheckbox"></vs-checkbox></div>
          <div class="w-full pl-2">-Abzüglich Rechnung 47270 - Teilrechung</div>
          <div class="w-1/4 text-right">4500€</div>
        </div>
        <div class="flex pb-4">
          <div class="w-1/4"> <vs-checkbox v-model="alreadyBeenBilledCheckbox"></vs-checkbox></div>
          <div class="w-full pl-2">-Abzüglich Rechnung 47270 - Teilrechung</div>
          <div class="w-1/4 text-right">4500€</div>
        </div>
        <div class="flex pb-4">
          <div class="w-1/4"> <vs-checkbox v-model="alreadyBeenBilledCheckbox"></vs-checkbox></div>
          <div class="w-full pl-2">-Abzüglich Rechnung 47270 - Teilrechung</div>
          <div class="w-1/4 text-right">4500€</div>

        </div>

        <div class="pt-4">
          <div class="flex billing-items pb-4">
            <span class="material-icons mr-4">warning</span>
            <span class="pt-1">Abrechnungspositionen in der Abrechnung zu diesem Angebot vorhanden</span>
          </div>
          <div class="draft-invoice flex pb-4">
            <span class="material-icons mr-4">check</span>
            <span class="pt-1">Keine "Entwurfsrechnung" vorhanden</span> </div>
          <div class="amount">Summe: 3480€</div>
        </div>
        <button type="button" @click="onSaveOrder" name="button" class="vs-component vs-button vs-button-success vs-button-filled mt-6"> Rechnung erstellen</button>
      </div>
    </vs-row>
  </div>
</template>
<script>

import MgSelect from "../../../../../components/mg-select/MgSelect";
import ApiService from "../../../../../api";

export default {
  name: "FixedPrice",
  components: {
    MgSelect
  },
  data() {
    return {
      order: null,
      select1:0,
      options1:[
        {text:'Gesamtsumme: 9800€',value:0},
        {text:'Summe (Personalaufwände): 8000€',value:2},
        {text:'Andere Summe:',value:3},
      ],
      alreadyBeenBilledCheckbox: true,
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'color': []}],
      ],
      total:'',
      otherAmount: '',
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const id = this.$route.params.id;
      this.$vs.loading()
      ApiService.get(`orders/${id}`).then(response => {
        this.order = response.data.result;
        this.$vs.loading.close()
        return this.getOrderItems();
      }).catch(response => {
      })
    },
    onSaveOrder() {
      const id = this.$route.params.id;
      const postData = {
        title: this.order.title,
        short_description: this.order.short_description,
      };

      this.$vs.loading();

      ApiService.put(`orders/${id}`, postData).then(response => {
        if (response.status === 200) {
          this.$vs.loading.close();

          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Änderung wurde erfolgreich gespeichert',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }
      }).catch(response => {
        this.$vs.loading.close();

        this.$vs.notify({
          title: 'Fehler',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      });
    }
  },
}
</script>

<style lang="scss">
.accounting-create-assignment {

}
</style>
